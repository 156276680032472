<template>
    <div class="monitor-index">
        <div class="container-header" v-if="!dangerid">
            <div class="top-actions-group">
                <div class="action-left">
                    <el-form class="geology-form">
                        <el-select
                            v-model="listfilter.dangerid"
                            filterable
                            remote
                            reserve-keyword
                            default-first-option
                            clearable
                            placeholder="选择监测点"
                            size="medium"
                            :remote-method="getDangerList"
                            :loading="getDangerLoading"
                            @change="onSearch"
                        >
                            <el-option
                                v-for="item in dangerSelect"
                                :key="item.Id"
                                :label="item.DangerName"
                                :value="item.Id"
                            >
                            </el-option>
                        </el-select>
                        <el-select
                            v-model="listfilter.DeviceType"
                            size="medium"
                            style="margin-left: 10px; width: 140px"
                            placeholder="设备类型"
                            clearable
                            @change="onSearch"
                        >
                            <el-option
                                v-for="item in DeviceTypeData"
                                :key="item.Id"
                                :label="item.ItemName"
                                :value="item.ItemValue"
                            >
                            </el-option>
                        </el-select>
                        <el-select
                            v-model="listfilter.state"
                            size="medium"
                            style="margin-left: 10px; width: 120px"
                            placeholder="设备状态"
                            clearable
                            @change="onSearch"
                        >
                            <el-option label="在线" value="1"></el-option>
                            <el-option label="离线" value="0"></el-option>
                        </el-select>
                        <el-date-picker
                            v-model="filterDaterange"
                            type="daterange"
                            range-separator="-"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            size="medium"
                            style="width: 240px; margin-left: 10px"
                            value-format="yyyy-MM-dd"
                            @change="dateChange"
                        >
                        </el-date-picker>

                        <el-input
                            placeholder="设备名称、编码"
                            style="width: 240px; margin-left: 10px"
                            size="medium"
                            class="form-input-group"
                            v-model="listfilter.fname"
                            @keyup.enter.native="onSearch"
                        >
                            <el-button
                                slot="append"
                                type="primary"
                                icon="el-icon-search"
                                @click="onSearch"
                                >查询</el-button
                            >
                        </el-input>
                    </el-form>
                </div>
                <div class="action-right">
                    <router-link to="/device/add">
                        <el-button
                            type="primary"
                            icon="el-icon-circle-plus-outline"
                            size="medium"
                            >新增</el-button
                        >
                    </router-link>
                </div>
            </div>
        </div>

        <el-table
            class="list-table list-table-border"
            ref="multipleTable"
            :data="listData"
            tooltip-effect="dark"
            style="width: 100%"
            :stripe="true"
            border
            v-loading="tableLoading"
            @selection-change="handleSelectionChange"
        >
            <el-table-column type="selection" width="55" align="center">
            </el-table-column>
            <el-table-column
                label="监测点位名称"
                :show-overflow-tooltip="true"
                prop="MonitorName"
            ></el-table-column>
            <el-table-column
                label="监测点名称"
                :show-overflow-tooltip="true"
                prop="DangerName"
            ></el-table-column>
            <el-table-column
                label="设备名称"
                :show-overflow-tooltip="true"
                prop="DeviceName"
            ></el-table-column>
            <el-table-column
                label="设备类型"
                prop="DeviceType"
            ></el-table-column>
            <el-table-column
                label="设备位置"
                :show-overflow-tooltip="true"
                prop="Address"
            ></el-table-column>
            <el-table-column label="状态" prop="IsOn">
                <template slot-scope="scope">
                    <el-tag
                        v-if="scope.row.IsOn == true"
                        effect="dark"
                        size="small"
                        >在线</el-tag
                    >
                    <el-tag
                        v-if="scope.row.IsOn == false"
                        type="danger"
                        effect="dark"
                        size="small"
                        >离线</el-tag
                    >
                </template>
            </el-table-column>
            <el-table-column label="最新时间" prop="LastUpdateTime">
                <template slot-scope="scope">
                    {{ scope.row.LastUpdateTime | datetime }}
                </template>
            </el-table-column>
            <el-table-column label="操作" width="240">
                <template slot-scope="scope">
                    <router-link :to="'/device/detail?id=' + scope.row.Id">
                        <el-button
                            type="success"
                            icon="el-icon-view"
                            size="mini"
                            >详情</el-button
                        >
                    </router-link>
                    <router-link :to="'/device/edit?id=' + scope.row.Id">
                        <el-button
                            type="success"
                            icon="el-icon-edit"
                            size="mini"
                            style="margin-left: 10px"
                            >修改</el-button
                        >
                    </router-link>
                    <el-dropdown trigger="click" @command="onListActions">
                        <el-button
                            type="primary"
                            size="mini"
                            style="margin-left: 10px"
                        >
                            更多<i
                                class="el-icon-arrow-down el-icon--right"
                            ></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item
                                :command="{ action: 'del', item: scope.row }"
                                >删除</el-dropdown-item
                            >
                            <el-dropdown-item
                                :command="{ action: 'qx', item: scope.row }"
                                >曲线</el-dropdown-item
                            >
                            <el-dropdown-item
                                :command="{ action: 'yj', item: scope.row }"
                                >预警信息</el-dropdown-item
                            >
                            <el-dropdown-item
                                :command="{ action: 'yjjs', item: scope.row }"
                                >预警接收</el-dropdown-item
                            >
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
            background
            layout="prev, pager, next"
            prev-text="上一页"
            next-text="下一页"
            class="list-pagination"
            :page-size="page.pagesize"
            hide-on-single-page
            :total="page.total"
            :current-page.sync="page.pageindex"
            @current-change="onPageChange"
        >
        </el-pagination>
    </div>
</template>
<script>
import { getDangerList } from "@/api/base";
import { getDeviceList, delDevice } from "@/api/device";
export default {
    name: "DeviceList",
    data() {
        return {
            DeviceTypeData: [],

            listData: [],
            tableLoading: false,
            filterDaterange: "",

            getDangerLoading: false,
            dangerSelect: [],

            listfilter: {
                dangerid: "",
                DeviceType: "",
                state: "",
                fname: "",
                fstarttime: "",
                fendtime: "",
            },
            page: {
                pagesize: 10,
                pageindex: 1,
                total: 0,
            },
            dialogDetailVisible: false,
            activeMonitorDetail: "",
        };
    },
    props: {
        dangerid: [String, Number],
    },
    components: {},
    methods: {
        getDangerList(value) {
            this.getDangerLoading = true;
            getDangerList(1, 10, {
                fname: value,
            }).then((resdata) => {
                this.getDangerLoading = false;
                if (resdata.code == 0) {
                    this.dangerSelect = resdata.data.data;
                }
            });
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        dateChange(value) {
            if (value) {
                var newdate = [];
                for (
                    let index = 0;
                    index < this.filterDaterange.length;
                    index++
                ) {
                    const element = this.filterDaterange[index];
                    newdate.push(element);
                }
                this.listfilter.fstarttime = newdate[0];
                this.listfilter.fendtime = newdate[1];
                this.getListData();
            } else {
                this.listfilter.fstarttime = "";
                this.listfilter.fendtime = "";
                this.getListData();
            }
        },
        onSearch() {
            this.page.pageindex = 1;
            this.getListData();
        },
        onPageChange(pageindex) {
            this.page.pageindex = pageindex;
            this.getListData();
        },
        getListData() {
            this.tableLoading = true;
            getDeviceList(
                this.page.pageindex,
                this.page.pagesize,
                this.listfilter
            ).then((resdata) => {
                this.tableLoading = false;
                if (resdata.code == 0) {
                    this.listData = resdata.data.data;
                    this.page.total = resdata.data.count * 1;
                } else {
                    this.$message({
                        type: "error",
                        message: resdata.msg,
                    });
                }
            });
        },
        onListActions(data) {
            switch (data.action) {
                case "detail":
                    this.$router.push("/device/detail?id=" + data.item.Id);
                    break;
                case "del":
                    this.onListDel(data.item);
                    break;
                case "qx":
                    this.$router.push("/device/detail?id=" + data.item.Id);
                    break;
                case "yj":
                    this.$router.push(
                        "/device/detail?tab=tabWarning&id=" + data.item.Id
                    );
                    break;
                case "yjjs":
                    this.$router.push("/warning/push?id=" + data.item.Id);
                    break;
                default:
                    break;
            }
        },
        onListDel(item) {
            this.$confirm("确定要删除选择的数据吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    delDevice(item.Id)
                        .then((res) => {
                            if (res.code == 0) {
                                this.$message.success("删除成功!");
                                this.getListData();
                            } else {
                                this.$message.error("删除失败!" + res.msg);
                            }
                        })
                        .catch((errmsg) => {
                            this.$message.error(errmsg);
                        });
                })
                .catch(() => {});
        },
        onShowDetail(item) {
            this.activeMonitorDetail = item;
            this.dialogDetailVisible = true;
        },
    },
    created() {
        if(this.dangerid){
            this.listfilter.dangerid = this.dangerid*1;
        }
        this.$store.dispatch("dictionary/getDeviceType").then((data) => {
            this.DeviceTypeData = data;
        });
        if(this.$route.query.dangerid){
            this.listfilter.dangerid = this.$route.query.dangerid*1;
        }
        this.getDangerList();
        this.getListData();

    },
    beforeRouteEnter(to, from, next){
        next(vm => {
            vm.$store.dispatch('global/addInclude','DeviceList');
        })
    },
    beforeRouteLeave(to, from, next){
        if(to.path!='/device/detail' && to.path!='/device/edit'){
            this.$store.dispatch('global/removeInclude','DeviceList');
        }
        next();
    },
    watch:{
        dangerid:()=>{
            this.listfilter.dangerid = this.dangerid*1;
        }
    },
};
</script>