import request from '@/common/request'


/**
 * 设备信息
 */


export function getDeviceList(pageindex, pagesize, filter) {
  return request.post('/api/Device/ListDevice', Object.assign({
    pageindex: pageindex,
    pagesize: pagesize ? pagesize : 20
  }, filter));
}
export function getDeviceDetail(id) {
  return request.post('/api/Device/DeviceDetail?deviceid=' + id, '');
}
export function addDevice(postdata) {
  return request.post('/api/Device/AddtDevice', postdata);
}
export function editDevice(postdata) {
  return request.post('/api/Device/EditDevice', postdata);
}
export function delDevice(id) {
  return request.post('/api/Device/DeleteDevice/' + id, '');
}

export function getDeviceInitvalue(id) {
  return request.post('/api/Device/DeviceInitList?deviceid=' + id, '');
}
export function delDeviceInitvalue(id) {
  return request.post('/api/Device/DeleteDeviceInit/' + id, '');
}
export function addDeviceInitvalue(postdata) {
  return request.post('/api/Device/AddtDeviceInit', postdata);
}
export function editDeviceInitvalue(postdata) {
  return request.post('/api/Device/EditDeviceInit', postdata);
}
export function editDeviceWaringStatus(deviceid, waring) {
  return request.post('/api/Device/DeviceWaring?deviceid=' + deviceid + "&waring=" + waring, {});
}

export function editDeviceVoiceNotice(deviceid, isnotice) {
  return request.post('/api/Device/EditVoiceNotice?deviceid=' + deviceid + "&isnotice=" + isnotice, {});
}

export function getDeviceLineData(postdata) {
  return request.post('/api/DeviceData/DeviceLineDataList', postdata);
}


export function getYljDeviceWaringData(postdata) {
  return request.post('/api/DeviceData/YLDeviceWaringLine', postdata);
}


export function getDeviceDataList(pageindex, pagesize, filter) {
  return request.post('/api/DeviceData/DeviceDataList', Object.assign({
    pageindex: pageindex,
    pagesize: pagesize ? pagesize : 20
  }, filter));
}

export function importDeviceData(filter) {
  return request.downfile('/api/DeviceData/OutDeviceData', filter);
}

export function setWaringConfig(deviceid, avgrate) {
  return request.post('/api/DeviceWaringConfig/EditWaringConfig?deviceid=' + deviceid + '&avgrate=' + avgrate, {}, 'form');
}
export function getWaringConfig(deviceid) {
  return request.post('/api/DeviceWaringConfig/GetWaringAvgrate?deviceid=' + deviceid, {}, 'form');
}

export function UpdateVideoPlay(deviceid) {
  return request.post('/api/Device/UpdateVideoPlay?deviceid=' + deviceid, {}, 'form');
}



/**
 * 获取设备曲线页面设备简易数据
 * @param {*} postdata fstarttime，fendtime，deviceid
 * @returns 
 */
export function getEasyDeviceLineDataList(postdata) {
  return request.post('/api/devicedata/EasyDeviceLineDataList', postdata, 'form');
}

/**
 * 预警管理
 */
export function getDeviceWarning(pageindex, pagesize, filter) {
  return request.post('/api/DeviceWaringConfig/WaringConfigList', Object.assign({
    pageindex: pageindex,
    pagesize: pagesize ? pagesize : 20
  }, filter));
}
export function delDeviceWarning(id) {
  return request.post('/api/DeviceWaringConfig/DeleteWaringConfig/' + id, '');
}
export function addDeviceWarning(postdata) {
  return request.post('/api/DeviceWaringConfig/AddtWaringConfig', postdata);
}
export function editDeviceWarning(postdata) {
  return request.post('/api/DeviceWaringConfig/EditWaringConfig', postdata);
}

export function getWarningMsgList(pageindex, pagesize, filter) {
  return request.post('/api/WaringMsg/ListWaringMsg', Object.assign({
    pageindex: pageindex,
    pagesize: pagesize ? pagesize : 20
  }, filter));
}

export function getWarningAcceptList(pageindex, pagesize, filter) {
  return request.post('/api/DeviceAcceptMsg/ListAcceptMsg', Object.assign({
    pageindex: pageindex,
    pagesize: pagesize ? pagesize : 20
  }, filter));
}

/**
 * 获取设备详情页预警接收人
 * @param {*} pageindex 
 * @param {*} pagesize 
 * @param {*} filter 
 * @returns 
 */
export function getDeviceWarningAcceptList(pageindex, pagesize, filter) {
  return request.post('/api/DeviceAcceptMsg/DeviceListAcceptMsg', Object.assign({
    pageindex: pageindex,
    pagesize: pagesize ? pagesize : 20
  }, filter));
}

export function delWarningAccept(id) {
  return request.post('/api/DeviceAcceptMsg/DeleteAcceptMsg/' + id, '');
}
export function addWarningAccept(postdata) {
  return request.post('/api/DeviceAcceptMsg/AddtAcceptMsg', postdata);
}
export function editWarningAccept(postdata) {
  return request.post('/api/DeviceAcceptMsg/EditAcceptMsg', postdata);
}
/**
 * 设备报告
 */
export function getDeviceReportList(pageindex, pagesize, filter) {
  return request.post('/api/ReportDoc/ListReport', Object.assign({
    pageindex: pageindex,
    pagesize: pagesize ? pagesize : 20
  }, filter));
}

/**
 * 创建设备报告
 * @param {*} postdata 
 * @returns 
 */
export function createDeviceReport(postdata) {
  return request.post('/api/ReportDoc/AddDevice', postdata);
}

export function deleteDeviceReport(id) {
  return request.post(`/api/ReportDoc/DeleteDevice/${id}`, '');
}

/**
 * 巡查记录
 */
export function getDeviceInspectList(pageindex, pagesize, filter) {
  return request.post('/api/Maintenance/ListMaintenance', Object.assign({
    pageindex: pageindex,
    pagesize: pagesize ? pagesize : 20
  }, filter));
}
export function addDeviceInspect(postdata) {
  return request.post('/api/Maintenance/AddMaintenance', postdata);
}

export function editDeviceInspect(postdata) {
  return request.post('/api/Maintenance/EditMaintenance', postdata);
}


/**
 * 命令下发
 */
export function getDeviceCommandList(pageindex, pagesize, filter) {
  return request.post('/api/PushCommand/ListCommand', Object.assign({
    pageindex: pageindex,
    pagesize: pagesize ? pagesize : 20
  }, filter));
}
export function addDeviceCommand(postdata) {
  return request.post('/api/PushCommand/AddCommand', postdata);
}
export function delDeviceCommand(id) {
  return request.post('/api/PushCommand/DeleteCommand/' + id, '');
}

/**
 * 固件升级
 */

export function getDeviceFirmwareList(pageindex, pagesize, filter) {
  return request.post('/api/PushCommand/ListFirmWare', Object.assign({
    pageindex: pageindex,
    pagesize: pagesize ? pagesize : 20
  }, filter));
}
export function addDeviceFirmware(postdata) {
  return request.post('/api/PushCommand/AddFirmWare', postdata);
}
export function delDeviceFirmware(id) {
  return request.post('/api/PushCommand/DeleteFirmWare/' + id, '');
}


/**
 * 测斜深度设置
 */
export function getDeviceCxDepth(deviceid) {
  return request.post('/api/Device/CxDepthList?deviceid=' + deviceid, '');
}
export function addDeviceCxDepth(postdata) {
  return request.post('/api/Device/AddCxDepth', postdata);
}
export function editDeviceCxDepth(postdata) {
  return request.post('/api/Device/EditCxDepth', postdata);
}
export function delDeviceCxDepth(id) {
  return request.post('/api/Device/DeleteCxDepth/' + id, '');
}


/**
 * mqtt信息
 */

export function getDeviceMqtt(deviceid) {
  return request.post('/api/Device/DeviceMqttDetail?deviceid=' + deviceid, '');
}
export function initDeviceMqtt(deviceid) {
  return request.post('/api/Device/AddMqtt?deviceid=' + deviceid, '');
}
export function editDeviceMqtt(postdata) {
  return request.post('/api/Device/EditMqtt', postdata);
}